import React from "react";
import { TextField } from "@mui/material";
import { NumericFormat } from "react-number-format";
import PropTypes from "prop-types";
import InputAdornment from "@mui/material/InputAdornment";

const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      decimalSeparator=","
      decimalScale={2}
      thousandSeparator="."
      valueIsNumericString
    />
  );
});

NumericFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const InputMoney = ({ value, setValue, name }) => {
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <TextField
      label={name}
      value={value}
      onChange={handleChange}
      name="numberformat"
      InputProps={{
        inputComponent: NumericFormatCustom,
        startAdornment: <InputAdornment position="start">R$</InputAdornment>,
        inputMode: "numeric",
      }}
      variant="outlined"
    />
  );
};

export default InputMoney;
