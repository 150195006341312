import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { ThemeProvider, styled } from "@mui/material/styles";
import React from "react";
import imageFaq from "../../Assets/Images/faq.png";
import BotaoWhats from "../../Components/BotaoWhats/BotaoWhats";
import { Theme } from "../../Themes/theme";

const Demo = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}));

const Faq = () => {
  return (
    <ThemeProvider theme={Theme}>
      <Box
        sx={{
          padding: "24px",
          display: "flex",
          flexDirection: "column",
          gap: "24px",
          alignContent: "center",
          marginRight: { xs: "0px", sm: "64px", md: "156px", lg: "256px" },
          marginLeft: { xs: "0px", sm: "64px", md: "156px", lg: "256px" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: "28px",
            }}
          >
            FAQ - PERGUNTAS FREQUENTES
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignContent: "center",
          }}
        >
          <Typography
            sx={{
              display: "flex",
              fontSize: { xs: "14px", md: "16px" },
              justifyContent: "center",
              color: "rgba(0, 0, 0, 0.7)",
            }}
          >
            Por se tratar de um processo com bastante complexidade, eu separei
            algumas perguntas e respostas que recebo com frequência para ajudar
            você a sanar as suas principais dúvidas.
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "center",
          }}
        >
          <Box maxWidth={"600px"}>
            <img src={imageFaq} alt="list document" width={"100%"}></img>
          </Box>
        </Box>

        <Box>
          <Accordion>
            <AccordionSummary
              sx={{
                backgroundColor: "#00259c",
                border: "1px solid white",
                borderRadius: "4px",
              }}
              expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
            >
              <Typography
                sx={{
                  color: "#ffffff",
                  fontWeight: 600,
                  fontSize: "12px",
                }}
              >
                QUEM ESTÁ OBRIGADO A FAZER A DECLARAÇÃO DE IMPOSTO DE RENDA?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Demo>
                <List>
                  <ListItem>
                    <ListItemText>
                      Pessoa física que recebeu rendimentos tributáveis acima do
                      limite (R$ 30.639,90), ou isentos, não tributáveis ou
                      tributados exclusivamente na fonte acima do limite (R$
                      200.000,00).
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      Ou demais obrigatoriedades conforme se verifica no site da
                      receita federal{" "}
                      <a target="_blank" href="https://www.gov.br/receitafederal/pt-br/assuntos/meu-imposto-de-renda/quem" rel="noreferrer" >
                      GOV.BR
                      </a>
                    </ListItemText>
                  </ListItem>
                </List>
              </Demo>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              sx={{
                backgroundColor: "#00259c",
                border: "1px solid white",
                borderRadius: "4px",
              }}
              expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
            >
              <Typography
                sx={{
                  color: "#ffffff",
                  fontWeight: 600,
                  fontSize: "12px",
                }}
              >
                NÃO ATINGI O LIMITE, MESMO ASSIM DEVO DECLARAR ?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Demo>
                <List>
                  <ListItem>
                    <ListItemText>
                      Se você não atingiu o limite estipulado pela RFB - Receita
                      Federal do Brasil, não está obrigado a declarar. Porém,
                      caso você não tenha atingido o limite, mas teve imposto de
                      renda descontado em folha de pagamento, você pode
                      recuperar este valor fazendo a declaração.
                    </ListItemText>
                  </ListItem>
                </List>
              </Demo>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              sx={{
                backgroundColor: "#00259c",
                border: "1px solid white",
                borderRadius: "4px",
              }}
              expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
            >
              <Typography
                sx={{
                  color: "#ffffff",
                  fontWeight: 600,
                  fontSize: "12px",
                }}
              >
                DESPESAS COM REMÉDIOS (FARMÁCIA) SÃO DEDUTÍVEIS DA BASE DE
                CÁLCULO?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Demo>
                <List>
                  <ListItem>
                    <ListItemText>
                      Infelizmente não. Se a pessoa compra diretamente na
                      farmácia, mesmo que seja sob recomendação médica e de uso
                      contínuo, o valor não pode ser deduzido da sua base de
                      cálculo.
                    </ListItemText>
                  </ListItem>
                </List>
              </Demo>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              sx={{
                backgroundColor: "#00259c",
                border: "1px solid white",
                borderRadius: "4px",
              }}
              expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
            >
              <Typography
                sx={{
                  color: "#ffffff",
                  fontWeight: 600,
                  fontSize: "12px",
                }}
              >
                QUAL A DIFERENÇA ENTRE DECLARAÇÃO SIMPLES E COMPLETA?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Demo>
                <List>
                  <ListItem>
                    <ListItemText>
                      Na declaração do IR simples, a Receita Federal utilizará o
                      desconto padrão de 20% sobre todos os seus rendimentos
                      tributáveis declarados, limitados a R$ 16.754,34.
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      Na declaração completa, dependerá dos seus gastos
                      dedutíveis, como despesas com educação (ensino
                      fundamental, médio, superior e pós-graduação), saúde ou
                      dependente(s).
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      Quanto mais despesas dedutíveis tiver, maior será a
                      restituição do seu Imposto de Renda. Para descobrir qual
                      das duas compensa mais utilize a{" "}
                      <a href="https://fernandobertuzzo.com/calculadora">
                        CALCULADORA DE IR
                      </a>{" "}
                      para fazer sua simulação.
                    </ListItemText>
                  </ListItem>
                </List>
              </Demo>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              sx={{
                backgroundColor: "#00259c",
                border: "1px solid white",
                borderRadius: "4px",
              }}
              expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
            >
              <Typography
                sx={{
                  color: "#ffffff",
                  fontWeight: 600,
                  fontSize: "12px",
                }}
              >
                O QUE É ACONTECE SE EU NÃO FIZER MINHA DECLARAÇÃO DE IR ?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Demo>
                <List>
                  <ListItem>
                    <ListItemText>
                      Além de bloquear seu CPF, a entrega da declaração depois
                      do prazo previsto ou a sua não apresentação, se
                      obrigatória, sujeita o contribuinte à multa de valor
                      mínimo de R$ 165,74 e valor máximo correspondente a 20% do
                      Imposto sobre a Renda devida, ainda que integralmente
                      pago.
                    </ListItemText>
                  </ListItem>
                </List>
              </Demo>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              sx={{
                backgroundColor: "#00259c",
                border: "1px solid white",
                borderRadius: "4px",
              }}
              expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
            >
              <Typography
                sx={{
                  color: "#ffffff",
                  fontWeight: 600,
                  fontSize: "12px",
                }}
              >
                QUANDO ACONTECE AS RESTITUIÇÕES ?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Demo>
                <List>
                  <ListItem>
                    <ListItemText>
                      A restituição será paga em cinco lotes, de maio a
                      setembro. O primeiro lote de restitução será pago em 31 de
                      maio e recebem as pessoas que têm prioridade legal. Depois
                      das prioridades, as restituições são pagas de acordo com a
                      data de envio da declaração. Quanto mais cedo entregar,
                      mais cedo o contribuinte pode receber.
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>1º lote - 31 de maio</ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>2º lote - 28 de junho</ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>3º lote - 31 de julho</ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>4º lote - 30 de agosto</ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>5º lote - 30 de setembro</ListItemText>
                  </ListItem>
                </List>
              </Demo>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              sx={{
                backgroundColor: "#00259c",
                border: "1px solid white",
                borderRadius: "4px",
              }}
              expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
            >
              <Typography
                sx={{
                  color: "#ffffff",
                  fontWeight: 600,
                  fontSize: "12px",
                }}
              >
                QUEM TEM PRIORIDADE NO PAGAMENTO DAS RESTITUIÇÕES ?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Demo>
                <List>
                  <ListItem>
                    <ListItemText>
                      A prioridade no pagamento segue a ordem:
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      1 - Contribuintes idosos com idade igual ou superior a 80 anos.
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      2 - Contribuintes idosos com idade igual/superior a 60 anos,
                      deficientes e portadores de moléstia grave.
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      3 - Contribuinte cuja maior fonte de renda seja o magistério.
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      4 - Contribuintes que utilizaram a pré-preenchida e/ou optaram
                      por receber a restituição por Pix.
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>5 - Demais contribuintes.</ListItemText>
                  </ListItem>
                </List>
              </Demo>
            </AccordionDetails>
          </Accordion>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignContent: "center",
          }}
        >
          <Typography
            sx={{
              display: "flex",
              fontSize: { xs: "14px", md: "16px" },
              justifyContent: "center",
              color: "rgba(0, 0, 0, 0.7)",
            }}
          >
            Se você ainda tem alguma dúvida, ou se precisa de um auxílio
            profissional para fazer a sua declaração é só me chamar enviando uma
            mensagem no WhatsApp através do botão abaixo.
          </Typography>
        </Box>

        <Box
          sx={{
            display: { xs: "", md: "flex" },
            flexDirection: { xs: "", md: "row" },
            justifyContent: { xs: "", md: "center" },
          }}
        >
          <BotaoWhats />
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default Faq;
