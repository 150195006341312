import React from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import imageIcon from "../../Assets/Images/icon-whatsapp.svg";
import { Typography } from "@mui/material";

const BotaoWhats = () => {
  let url =
    "https://wa.me/5514981674373?text=Ol%C3%A1%2C%20fiz%20minha%20simula%C3%A7%C3%A3o%20de%20IR%20no%20seu%20site";

  return (
    <Box
      sx={{
        width: { sx: "100%", md: "700px" },
        height: "56px",
        padding: "4px",
      }}
    >
      <Button
        variant="contained"
        sx={{ width: "100%", height: "56px", backgroundColor: "#40C351" }}
        onClick={() => {
          window.location.href = url;
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              width: "20%",
            }}
          >
            <img src={imageIcon} alt="icon whats" width={"65%"} />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              width: "80%",
            }}
          >
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "14px",
              }}
            >
              FAZER DECLARAÇÃO
            </Typography>
          </Box>
        </Box>
      </Button>
    </Box>
  );
};

export default BotaoWhats;
