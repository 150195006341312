import CloseIcon from "@mui/icons-material/Close";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Tooltip } from "@mui/material";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Fab from "@mui/material/Fab";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { ThemeProvider, styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import React from "react";
import BotaoWhats from "../../Components/BotaoWhats/BotaoWhats";
import InputMoney from "../../Components/InputMoney/InputMoney";
import { Theme } from "../../Themes/theme";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const Calculadora = () => {
  const regex = /^[0-9\b]+$/;

  const handleCalculate = () => {
    setTotalGeral(Number(totalImposto) - Number(irrf));
    handleClickOpen();
  };

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [openAlert, setOpenAlert] = React.useState(false);
  const handleClickOpenAlert = () => {
    setOpenAlert(true);
  };
  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleChangeRendimentos = (e) => {
    if (e.target.value === "" || regex.test(e.target.value)) {
      setRendimentos(Number(e.target.value));
    }
  };

  const handleChangePrevidencia = (e) => {
    if (e.target.value === "" || regex.test(e.target.value)) {
      setPrevidencia(Number(e.target.value));
    }
  };

  const handleChangeDependentes = (e) => {
    if (e.target.value === "" || regex.test(e.target.value)) {
      setDependentes(e.target.value);
    }
  };

  const handleChangeInstrucao = (e) => {
    if (e.target.value === "" || regex.test(e.target.value)) {
      if (Number(e.target.value) < 3562 * (Number(dependentes) + 1)) {
        setInstrucao(Number(e.target.value));
      } else {
        handleClickOpenAlert();
        setInstrucao(Number(3561.5 * (Number(dependentes) + 1)));
      }
    }
  };

  const handleChangeDespMedica = (e) => {
    if (e.target.value === "" || regex.test(e.target.value)) {
      setDespMedica(Number(e.target.value));
    }
  };

  const handleChangePensao = (e) => {
    if (e.target.value === "" || regex.test(e.target.value)) {
      setPensao(Number(e.target.value));
    }
  };

  const handleChangeOutrasDeducoes = (e) => {
    if (e.target.value === "" || regex.test(e.target.value)) {
      setOutrasDeducoes(Number(e.target.value));
    }
  };

  const handleChangeIrrf = (e) => {
    if (e.target.value === "" || regex.test(e.target.value)) {
      setIrrf(Number(e.target.value));
    }
  };

  const handleChangeDeducao = (event) => {
    setRadioDeducao(event.target.value);
  };

  function createData(base, aliquota, deducao) {
    return { base, aliquota, deducao };
  }

  const [radioDeducao, setRadioDeducao] = React.useState("simples");
  const [rendimentos, setRendimentos] = React.useState("");
  const [deducoesText, setDeducoesText] = React.useState("");
  const [baseCalculo, setBaseCalculo] = React.useState(0);
  const [baseCalculoText, setBaseCalculoText] = React.useState("");

  const [previdencia, setPrevidencia] = React.useState("");
  const [dependentes, setDependentes] = React.useState("");
  const [instrucao, setInstrucao] = React.useState("");
  const [despMedica, setDespMedica] = React.useState("");
  const [pensao, setPensao] = React.useState("");
  const [outrasDeducoes, setOutrasDeducoes] = React.useState("");

  const [aliquota, setAliquota] = React.useState(0);
  const [deducaoText, setDeducaoText] = React.useState("");
  const [totalImposto, setTotalImposto] = React.useState(0);
  const [totalImpostoText, setTotalImpostoText] = React.useState(0);
  const [irrf, setIrrf] = React.useState("");

  const [totalGeral, setTotalGeral] = React.useState(0);

  const formatter = (value) =>
    value.toLocaleString("pt-br", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

  const impostoPagar = (baseDeCalculo, aliquota, deducao) => baseDeCalculo * aliquota - deducao;

  const SIMPLES = "simples";
  const PT_BR = "pt-br";

  const DESCONTO_SIMPLIFICADO_MAXIMO = 16754.34;
  const DEDUCAO_DEPENDENTE = 2275.08;

  const ZERO = 0;
  const VINTE_PORCENTO = 0.2;

  const DEDUCAO_FAIXA_1 = 1838.39;
  const DEDUCAO_FAIXA_2 = 4382.38;
  const DEDUCAO_FAIXA_3 = 7758.32;
  const DEDUCAO_FAIXA_4 = 10557.13;

  const FAIXA_1 = 24511.93;
  const FAIXA_2 = 33919.8;
  const FAIXA_3 = 45012.6;
  const FAIXA_4 = 55976.16;

  const FAIXA_0_TXT = "0,00";
  const FAIXA_1_TXT = "1.838,39";
  const FAIXA_2_TXT = "4.382,38";
  const FAIXA_3_TXT = "7.758,32";
  const FAIXA_4_TXT = "10.557,13";

  const ALIQUOTA_1 = 7.5;
  const ALIQUOTA_2 = 15;
  const ALIQUOTA_3 = 22.5;
  const ALIQUOTA_4 = 27.5;

  const ALIQUOTA_1_TXT = "7,5%";
  const ALIQUOTA_2_TXT = "15%";
  const ALIQUOTA_3_TXT = "22,5%";
  const ALIQUOTA_4_TXT = "27,5%";
  
  const ALIQUOTA_1_PCT = 0.075;
  const ALIQUOTA_2_PCT = 0.15;
  const ALIQUOTA_3_PCT = 0.225;
  const ALIQUOTA_4_PCT = 0.275;

  const rows = [
    createData("Até R$ 24.511,93", "-", "-"),
    createData("De R$ 24.511,93 até R$ 33.919,80", ALIQUOTA_1_TXT, FAIXA_1_TXT),
    createData("De R$ 33.919,81 até R$ 45.012,60", ALIQUOTA_2_TXT, FAIXA_2_TXT),
    createData("De R$ 45.012,61 até R$ 55.976,16", ALIQUOTA_3_TXT, FAIXA_3_TXT),
    createData("Acima de R$ 55.976,16", ALIQUOTA_4_TXT, FAIXA_4_TXT),
  ];

  React.useEffect(() => {
    
    const setValues = (aliquota, faixaTxt, imposto, impostoTxt) => {
      setAliquota(aliquota);
      setDeducaoText(faixaTxt);
      setTotalImposto(imposto);
      setTotalImpostoText(formatter(impostoTxt));
    }

    const setValuesBase = (deducoes, baseCalculo) => {
      setDeducoesText(formatter(deducoes));
      setBaseCalculo(baseCalculo);
      setBaseCalculoText(formatter(baseCalculo));
    }

    const calculoGeral = (baseCalculo) => {
      if (baseCalculo <= FAIXA_1) {
        setValues(ZERO,FAIXA_0_TXT,ZERO,formatter(ZERO));
      } else if (baseCalculo <= FAIXA_2) {
        var impostoFaixa_1 = impostoPagar(baseCalculo, ALIQUOTA_1_PCT, DEDUCAO_FAIXA_1);
        setValues(ALIQUOTA_1,FAIXA_1_TXT,impostoFaixa_1,formatter(impostoFaixa_1));          
      } else if (baseCalculo <= FAIXA_3) {
        var impostoFaixa_2 = impostoPagar(baseCalculo, ALIQUOTA_2_PCT, DEDUCAO_FAIXA_2);
        setValues(ALIQUOTA_2,FAIXA_2_TXT,impostoFaixa_2,formatter(impostoFaixa_2));
      } else if (baseCalculo <= FAIXA_4) {
        var impostoFaixa_3 = impostoPagar(baseCalculo, ALIQUOTA_3_PCT, DEDUCAO_FAIXA_3);
        setValues(ALIQUOTA_3,FAIXA_3_TXT,impostoFaixa_3,formatter(impostoFaixa_3));
      } else {
        var impostoFaixa_4 = impostoPagar(baseCalculo, ALIQUOTA_4_PCT, DEDUCAO_FAIXA_4);
        setValues(ALIQUOTA_4,FAIXA_4_TXT,impostoFaixa_4,formatter(impostoFaixa_4));
      }
    }

    if (radioDeducao === SIMPLES) {
      if (Number(rendimentos) * VINTE_PORCENTO > DESCONTO_SIMPLIFICADO_MAXIMO) {
        var baseCalculoLocal = Number(rendimentos) - Number(DESCONTO_SIMPLIFICADO_MAXIMO);
        setValuesBase(Number(DESCONTO_SIMPLIFICADO_MAXIMO),baseCalculoLocal);
        calculoGeral(baseCalculoLocal);
      } else {
        var deducaoLocal = Number(rendimentos) * VINTE_PORCENTO;
        var baseCalculoSimples = Number(rendimentos) - deducaoLocal;
        setValuesBase(deducaoLocal,baseCalculoSimples);
        calculoGeral(baseCalculoSimples);        
      }
    } else {
      var deducoesCompleta = Number(previdencia) + Number(dependentes) * DEDUCAO_DEPENDENTE + Number(instrucao) + Number(despMedica) + Number(pensao) + Number(outrasDeducoes);
      var baseCalculoLocalCompleta = Number(rendimentos) - deducoesCompleta;
      setValuesBase(deducoesCompleta,baseCalculoLocalCompleta);
      calculoGeral(baseCalculoLocalCompleta);      
    }
  }, [
    rendimentos,
    radioDeducao,
    previdencia,
    dependentes,
    instrucao,
    despMedica,
    pensao,
    outrasDeducoes,
  ]);

  return (
    <ThemeProvider theme={Theme}>
      <Box
        sx={{
          padding: "24px",
          display: "flex",
          flexDirection: "column",
          gap: "24px",
          alignContent: "center",
          marginRight: { xs: "0px", sm: "64px", md: "156px", lg: "256px" },
          marginLeft: { xs: "0px", sm: "64px", md: "156px", lg: "256px" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: "28px",
            }}
          >
            CALCULADORA
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignContent: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: "14px", md: "16px" },
              color: "rgba(0, 0, 0, 0.7)",
            }}
          >
            A seguinte calculadora pode ser usada para fazer uma
            <strong> ESTIMATIVA</strong> de qual valor você deverá
            <strong> PAGAR </strong>ou <strong> RESTITUIR</strong> ao fazer a
            sua declaração de Imposto de Renda.
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignContent: "center",
          }}
        >
          <Alert severity="info" sx={{ width: "100%", alignItems: "center" }}>
            <Typography
              sx={{
                fontSize: { xs: "12px", md: "14px" },
              }}
            >
              Mesmo que você{" "}
              <strong> NÃO SEJA OBRIGADO A FAZER A DECLARAÇÃO </strong>{" "}
              recomendo que faça a estimativa, pois você{" "}
              <strong> PODE TER ALGUM VALOR A RESTITUIR.</strong>
            </Typography>
          </Alert>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignContent: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: "14px", md: "16px" },
              color: "rgba(0, 0, 0, 0.7)",
            }}
          >
            <strong> 1° PASSO: </strong>Encontre sua BÁSE DE CÁLCULO.
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          <Paper
            sx={{
              padding: "24px",
              display: "flex",
              width: { xs: "calc(100% - 48px)", lg: "800px" },
              flexDirection: "column",
              gap: "24px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignContent: "center",
                justifyContent: "center",
              }}
            >
              <FormLabel id="demo-controlled-radio-buttons-group">
                <Typography
                  sx={{
                    fontSize: { xs: "14px", md: "16px" },
                  }}
                >
                  Rendimentos:
                  <Tooltip
                    sx={{ padding: 0, mx: 1 }}
                    title="Informe o Total dos rendimentos (inclusive férias) que você obteve durante o ano."
                    arrow
                    enterTouchDelay={0}
                  >
                    <IconButton>
                      <InfoOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                </Typography>
              </FormLabel>
              <FormControl sx={{ width: { xs: "100%", md: "440px" }, mt: 2 }}>
                <InputMoney
                  value={rendimentos}
                  setValue={setRendimentos}
                  onChange={handleChangeRendimentos}
                  name={"Rendimentos Tributáveis"}
                />
              </FormControl>

              <Divider sx={{ width: "100%", my: 2 }} />
              <FormControl>
                <FormLabel id="demo-controlled-radio-buttons-group">
                  <Typography
                    sx={{
                      fontSize: { xs: "14px", md: "16px" },
                    }}
                  >
                    Deduções:
                    <Tooltip
                      sx={{ padding: 0, mx: 1 }}
                      title="Na dedução simplificada, será descontado 20% do valor dos rendimentos (limitado à R$16.754,34)"
                      arrow
                      enterTouchDelay={0}
                    >
                      <IconButton>
                        <InfoOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                  </Typography>
                </FormLabel>
                <RadioGroup
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignContent: "center",
                  }}
                  value={radioDeducao}
                  onChange={handleChangeDeducao}
                >
                  <FormControlLabel
                    value="simples"
                    control={<Radio />}
                    label="Simplificada"
                  />
                  <FormControlLabel
                    value="completa"
                    control={<Radio />}
                    label="Completa"
                  />
                </RadioGroup>
              </FormControl>
              {radioDeducao === "completa" && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: { xs: "100%", md: "440px" },
                    mt: 2,
                    gap: 2,
                  }}
                >
                  <FormControl sx={{ width: "100%" }}>
                    <Typography
                      sx={{
                        fontSize: { xs: "12px", md: "14px" },
                        color: "rgba(0, 0, 0, 0.7)",
                      }}
                    >
                      Previdência oficial:
                    </Typography>
                    <InputMoney
                      value={previdencia}
                      setValue={setPrevidencia}
                      onChange={handleChangePrevidencia}
                    />
                  </FormControl>
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: { xs: "12px", md: "14px" },
                          color: "rgba(0, 0, 0, 0.7)",
                        }}
                      >
                        Número de dependentes:
                      </Typography>
                      <Tooltip
                        sx={{ padding: 0, mx: 1 }}
                        title="O valor da dedução é R$ 2.275,08 anuais, por dependente."
                        arrow
                        enterTouchDelay={0}
                      >
                        <IconButton>
                          <InfoOutlinedIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                    <TextField
                      sx={{ width: "100%" }}
                      variant="outlined"
                      value={dependentes}
                      onChange={(event) => {
                        handleChangeDependentes(event);
                      }}
                    />
                  </Box>
                  <Box>
                    <FormControl sx={{ width: "100%" }}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: { xs: "12px", md: "14px" },
                            color: "rgba(0, 0, 0, 0.7)",
                          }}
                        >
                          Despesa com instrução:
                        </Typography>
                        <Tooltip
                          sx={{ padding: 0, mx: 1 }}
                          title="Limitada a R$ 3.561,50 anuais para o titular e para cada dependente ou alimentando com os quais o titular efetuou despesas com instrução."
                          arrow
                          enterTouchDelay={0}
                        >
                          <IconButton>
                            <InfoOutlinedIcon />
                          </IconButton>
                        </Tooltip>
                      </Box>
                      <InputMoney
                        value={instrucao}
                        setValue={setInstrucao}
                        onChange={handleChangeInstrucao}
                      />
                    </FormControl>
                  </Box>
                  <Box>
                    <FormControl sx={{ width: "100%" }}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: { xs: "12px", md: "14px" },
                            color: "rgba(0, 0, 0, 0.7)",
                          }}
                        >
                          Despesa médica:
                        </Typography>
                        <Tooltip
                          sx={{ padding: 0, mx: 1 }}
                          title="Valor gasto em consultas médicas, odontológicas e afins, não sendo considerado valor gasto com medicamentos e farmácias."
                          arrow
                          enterTouchDelay={0}
                        >
                          <IconButton>
                            <InfoOutlinedIcon />
                          </IconButton>
                        </Tooltip>
                      </Box>
                      <InputMoney
                        value={despMedica}
                        setValue={setDespMedica}
                        onChange={handleChangeDespMedica}
                      />
                    </FormControl>
                  </Box>
                  <Box>
                    <FormControl sx={{ width: "100%" }}>
                      <Typography
                        sx={{
                          fontSize: { xs: "12px", md: "14px" },
                          color: "rgba(0, 0, 0, 0.7)",
                        }}
                      >
                        Pensão alimentícia:
                      </Typography>
                      <InputMoney
                        value={pensao}
                        setValue={setPensao}
                        onChange={handleChangePensao}
                      />
                    </FormControl>
                  </Box>
                  <Box>
                    <FormControl sx={{ width: "100%" }}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: { xs: "12px", md: "14px" },
                            color: "rgba(0, 0, 0, 0.7)",
                          }}
                        >
                          Outras deduções:
                        </Typography>
                        <Tooltip
                          sx={{ padding: 0, mx: 1 }}
                          title="Previdência Privada, Funpresp, FAPI e Parcela isenta de aposentadoria, reserva remunerada, reforma e pensão para declarante com 65 anos ou mais, caso não tenha sido deduzida dos rendimentos tributáveis. Carne-Leão: Livro Caixa."
                          arrow
                          enterTouchDelay={0}
                        >
                          <IconButton>
                            <InfoOutlinedIcon />
                          </IconButton>
                        </Tooltip>
                      </Box>
                      <InputMoney
                        value={outrasDeducoes}
                        setValue={setOutrasDeducoes}
                        onChange={handleChangeOutrasDeducoes}
                      />
                    </FormControl>
                  </Box>
                </Box>
              )}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  width: "100%",
                  mt: 3,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: { xs: "14px", md: "16px" },
                    }}
                  >
                    Total de deduções:
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    width: "70%",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: { xs: "14px", md: "16px" },
                    }}
                  >
                    R$ {deducoesText}
                  </Typography>
                </Box>
              </Box>
              <Divider sx={{ width: "100%", my: 2 }} />
              <Box>
                <Typography
                  sx={{
                    fontSize: { xs: "14px", md: "16px" },
                    color: "rgba(0, 0, 0, 0.7)",
                  }}
                >
                  Base de cálculo:
                  <Tooltip
                    sx={{ padding: 0, mx: 1 }}
                    title="A base de cálculo é encontrada fazendo os valores de rendimento menos as deduções."
                    arrow
                    enterTouchDelay={0}
                  >
                    <IconButton>
                      <InfoOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  mt: 1,
                }}
              >
                <Typography
                  sx={{
                    fontSize: { xs: "16px", md: "18px" },
                    color: "rgba(0, 0, 0, 0.7)",
                  }}
                >
                  <strong>R$ {baseCalculoText}</strong>
                </Typography>
              </Box>
            </Box>
          </Paper>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignContent: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: "14px", md: "16px" },
              color: "rgba(0, 0, 0, 0.7)",
            }}
          >
            <strong> 2° PASSO: </strong>Encontre sua ALÍQUOTA.
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          <TableContainer
            sx={{
              maxWidth: { xs: "100%", md: "848px" },
            }}
            component={Paper}
          >
            <Table
              sx={{
                minWidth: { xs: "560px", md: "800px" },
              }}
              aria-label="simple table"
            >
              <TableHead sx={{ backgroundColor: "#00259c" }}>
                <TableRow>
                  <TableCell sx={{ color: "white" }}>
                    <strong>BASE DE CÁLCULO (R$)</strong>
                  </TableCell>
                  <TableCell sx={{ color: "white" }} align="center">
                    <strong>ALÍQUOTA</strong>
                  </TableCell>
                  <TableCell sx={{ color: "white" }} align="center">
                    <strong>DEDUÇÃO (R$)</strong>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow
                    key={row.base}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      backgroundColor:
                        baseCalculo === 0
                          ? ""
                          : baseCalculo <= FAIXA_1 && row.aliquota === "-"
                          ? "#E5F6FD"
                          : baseCalculo > FAIXA_1 &&
                            baseCalculo <= FAIXA_2 &&
                            row.aliquota === ALIQUOTA_1_TXT
                          ? "#E5F6FD"
                          : baseCalculo > FAIXA_2 &&
                            baseCalculo <= FAIXA_3 &&
                            row.aliquota === ALIQUOTA_2_TXT
                          ? "#E5F6FD"
                          : baseCalculo > FAIXA_3 &&
                            baseCalculo <= FAIXA_4 &&
                            row.aliquota === ALIQUOTA_3_TXT
                          ? "#E5F6FD"
                          : baseCalculo > FAIXA_4 &&
                            row.aliquota === ALIQUOTA_4_TXT
                          ? "#E5F6FD"
                          : "",
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {row.base}
                    </TableCell>
                    <TableCell align="center">{row.aliquota}</TableCell>
                    <TableCell align="center">{row.deducao}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignContent: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: "14px", md: "16px" },
              color: "rgba(0, 0, 0, 0.7)",
            }}
          >
            <strong> 3° PASSO: </strong>Descubra o valor de IMPOSTO À PAGAR.
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Paper
            sx={{
              padding: "24px",
              display: "flex",
              flexDirection: "column",
              gap: "24px",
              width: { xs: "calc(100% - 48px)", lg: "800px" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignContent: "center",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignContent: "center",
                }}
              >
                <Typography
                  sx={{
                    fontSize: { xs: "10px", md: "12px" },
                    color: "rgba(0, 0, 0, 0.7)",
                  }}
                >
                  (base de cálculo x alíquota) - dedução = TOTAL
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  mt: 1,
                }}
              >
                <Typography
                  sx={{
                    fontSize: { xs: "13px", md: "16px" },
                    color: "rgba(0, 0, 0, 0.7)",
                  }}
                >
                  R$ {baseCalculoText}
                </Typography>
                <Typography
                  sx={{
                    fontSize: { xs: "13px", md: "16px" },
                    color: "rgba(0, 0, 0, 0.7)",
                    marginX: 1,
                  }}
                >
                  x
                </Typography>
                <Typography
                  sx={{
                    fontSize: { xs: "13px", md: "16px" },
                    color: "rgba(0, 0, 0, 0.7)",
                  }}
                >
                  {aliquota}% - R$ {deducaoText} =
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  width: "100%",
                  mt: 3,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    width: { xs: "50%", md: "30%" },
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: { xs: "14px", md: "16px" },
                    }}
                  >
                    <strong>Imposto à pagar:</strong>
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    width: { xs: "50%", md: "70%" },
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: { xs: "14px", md: "16px" },
                    }}
                  >
                    <strong>R$ {totalImpostoText}</strong>
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Paper>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignContent: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: "14px", md: "16px" },
              color: "rgba(0, 0, 0, 0.7)",
            }}
          >
            <strong> 4° PASSO: </strong>Desconte o IRRF (Imposto sobre a Renda
            Retido na Fonte).
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Paper
            sx={{
              padding: "24px",
              display: "flex",
              flexDirection: "column",
              gap: "24px",
              width: { xs: "calc(100% - 48px)", lg: "800px" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignContent: "center",
                justifyContent: "center",
              }}
            >
              <FormControl sx={{ width: "100%" }}>
                <Typography
                  sx={{
                    fontSize: { xs: "12px", md: "14px" },
                    color: "rgba(0, 0, 0, 0.7)",
                  }}
                >
                  IRRF:
                </Typography>
                <InputMoney
                  value={irrf}
                  setValue={setIrrf}
                  onChange={handleChangeIrrf}
                />
              </FormControl>
            </Box>
          </Paper>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Fab
            sx={{ width: { xs: "100%", md: "50%" } }}
            onClick={handleCalculate}
            variant="extended"
            color="primary"
            aria-label="add"
          >
            FINALIZAR CÁLCULO
          </Fab>
        </Box>
      </Box>
      <Dialog
        open={openAlert}
        onClose={handleCloseAlert}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          sx={{ backgroundColor: "#FDF0F0" }}
          id="alert-dialog-title"
        >
          <Alert severity="error">
            <AlertTitle>
              <strong>ATENÇÃO</strong>
            </AlertTitle>
            A dedução de Despesa com Instrução{" "}
            <strong>
              não pode ser superior ao valor de R$ 3.561,50 por pessoa
            </strong>
            , somando o titular ao número de dependentes.
          </Alert>
        </DialogTitle>
        <DialogActions
          sx={{
            backgroundColor: "#FDF0F0",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button onClick={handleCloseAlert}>Ok</Button>
        </DialogActions>
      </Dialog>
      <BootstrapDialog
        onClose={handleClose}
        fullWidth
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          RESULTADO
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Paper
            sx={{
              padding: "24px",
              display: "flex",
              flexDirection: "column",
              gap: "24px",
              width: { xs: "calc(100% - 48px)" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignContent: "center",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    width: { xs: "60%", md: "50%" },
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: { xs: "14px", md: "16px" },
                    }}
                  >
                    Imposto à pagar
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    width: { xs: "40%", md: "50%" },
                  }}
                >
                  <Typography
                    sx={{
                      color: "red",
                      fontSize: { xs: "14px", md: "16px" },
                    }}
                  >
                    <strong>R$ {totalImpostoText}</strong>
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    width: { xs: "60%", md: "50%" },
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: { xs: "14px", md: "16px" },
                    }}
                  >
                    Imposto pago
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    width: { xs: "40%", md: "50%" },
                  }}
                >
                  <Typography
                    sx={{
                      color: "green",
                      fontSize: { xs: "14px", md: "16px" },
                    }}
                  >
                    <strong>
                      R${" "}
                      {Number(irrf).toLocaleString(PT_BR, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </strong>
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  width: "100%",
                  mt: 2,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    width: { xs: "50%", md: "50%" },
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: { xs: "14px", md: "16px" },
                    }}
                  >
                    TOTAL À{totalGeral > 0 ? " PAGAR" : " RESTITUIR"}:
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    width: { xs: "50%", md: "50%" },
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: { xs: "14px", md: "16px" },
                      color: totalGeral > 0 ? "red" : "green",
                    }}
                  >
                    <strong>
                      R$
                      {Math.abs(totalGeral).toLocaleString(PT_BR, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </strong>
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Paper>
        </DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
          <BotaoWhats />
        </DialogActions>
      </BootstrapDialog>
    </ThemeProvider>
  );
};

export default Calculadora;
