import { CardActionArea } from "@mui/material";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Fab from "@mui/material/Fab";
import Typography from "@mui/material/Typography";
import { ThemeProvider } from "@mui/material/styles";
import React from "react";
import { useNavigate } from "react-router-dom";
import imagePerfil from "../../Assets/Images/fernando-perfil.png";
import imageIr from "../../Assets/Images/ir-2023.png";
import BotaoWhats from "../../Components/BotaoWhats/BotaoWhats";
import { Theme } from "../../Themes/theme";

const Declaracao = () => {
  let navigate = useNavigate();

  return (
    <ThemeProvider theme={Theme}>
      <Box
        sx={{
          padding: "24px",
          display: "flex",
          flexDirection: "column",
          gap: "24px",
          alignContent: "center",
          marginRight: { xs: "0px", sm: "64px", md: "156px", lg: "256px" },
          marginLeft: { xs: "0px", sm: "64px", md: "156px", lg: "256px" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: "28px",
            }}
          >
            DECLARAÇÃO IR 2024
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "center",
          }}
        >
          <Box maxWidth={"700px"}>
            <img src={imageIr} alt="list document" width={"100%"}></img>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignContent: "center",
          }}
        >
          <Typography
            sx={{
              display: "flex",
              fontWeight: 600,
              fontSize: { xs: "16px", md: "20px" },
              justifyContent: "center",
            }}
          >
            O que é o Imposto de Renda?
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignContent: "center",
          }}
        >
          <Typography
            sx={{
              display: "flex",
              fontSize: { xs: "12px", md: "16px" },
              justifyContent: "center",
              color: "rgba(0, 0, 0, 0.7)",
            }}
          >
            O Imposto de Renda (IR) é uma declaração exigida anualmente pelo
            governo federal sobre os valores ganhos por pessoas e empresas. Essa
            declaração deve conter todos os rendimentos obtidos durante o último
            ano. Como o Imposto de Renda é aplicado a pessoas e também a
            empresas, ele se divide em Imposto de Renda Pessoa Física (IRPF) e
            Imposto de Renda Pessoa Jurídica (IRPJ).
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            alignContent: "center",
          }}
        >
          <Typography
            sx={{
              display: "flex",
              fontWeight: 600,
              fontSize: { xs: "16px", md: "20px" },
              justifyContent: "center",
            }}
          >
            Quem deve declarar ?
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignContent: "center",
          }}
        >
          <Typography
            sx={{
              display: "flex",
              flex: 'row',
              fontSize: { xs: "12px", md: "16px" },
              justifyContent: "center",
              color: "rgba(0, 0, 0, 0.7)",
            }}
          >
            Todas as pessoas que tiveram, durante o ano de 2023, rendimentos tributáveis acima do limite
            de R$ 30.639,90, de acordo com a Instrução Normativa da Receita Federal n° 2.178/2024. Essa
            é a regra mais conhecida para as declarações de IRPF, no entanto há outros critérios que
            tornam a declaração obrigatória, conforme podemos verificar no link abaixo:
          </Typography>          
        </Box>
        <Box>
          <a target="_blank" href="https://www.gov.br/receitafederal/pt-br/assuntos/meu-imposto-de-renda/quem" rel="noreferrer" >https://www.gov.br/receitafederal/pt-br/assuntos/meu-imposto-de-renda/quem</a>
        </Box>        

        <Box
          sx={{
            display: "flex",
            alignContent: "center",
          }}
        >
          <Alert severity="info" sx={{ width: "100%", alignItems: "center" }}>
            <Typography
              sx={{
                fontSize: { xs: "12px", md: "14px" },
              }}
            >
              Mesmo que você{" "}
              <strong> NÃO SEJA OBRIGADO A FAZER A DECLARAÇÃO </strong>{" "}
              recomendo que faça a estimativa, pois você{" "}
              <strong> PODE TER ALGUM VALOR A RESTITUIR.</strong>
            </Typography>
          </Alert>
        </Box>

        <Box
          sx={{
            display: "flex",
            alignContent: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: "12px", md: "16px" },
              color: "rgba(0, 0, 0, 0.7)",
            }}
          >
            Para saber se você possuí <strong>ALGUM VALOR A RESTITUIR </strong>
            ou se você tem <strong>IMPOSTO À PAGAR</strong> utilize a
            calculadora que eu desenvolvi para auxiliar as pessoas, clicando no
            botão abaixo.
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Fab
            sx={{ width: { xs: "100%", md: "700px" } }}
            variant="extended"
            color="primary"
            aria-label="add"
            onClick={() => {
              navigate("/calculadora");
            }}
          >
            USAR CALCULADORA DE IR
          </Fab>
        </Box>

        <Box
          sx={{
            display: "flex",
            alignContent: "center",
          }}
        >
          <Typography
            sx={{
              display: "flex",
              fontWeight: 600,
              fontSize: { xs: "16px", md: "20px" },
              justifyContent: "center",
            }}
          >
            Como declarar ?
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignContent: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: "12px", md: "16px" },
              justifyContent: "center",
              color: "rgba(0, 0, 0, 0.7)",
            }}
          >
            Você pode optar por realizar você mesmo a sua Declaração de Imposto
            de Renda, mas é um processo bastante burocrático, que exige uma{" "}
            <a href="https://fernandobertuzzo.com/documentos">
              DOCUMENTAÇÃO BASTANTE EXTENSA (confira a lista aqui)
            </a>{" "}
            e caso feito de forma incorreta você poderá cair na "malha fina"
            (termo utilizado para quando o documento fica retido até que se
            esclareça os possíveis erros da declaração) e recebar multas.
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            alignContent: "center",
          }}
        >
          <Typography
            sx={{
              display: "flex",
              fontSize: { xs: "12px", md: "16px" },
              justifyContent: "center",
              color: "rgba(0, 0, 0, 0.7)",
            }}
          >
            Portanto o mais indicado é que você procure um profissional
            capacitado que possa te orientar e auxiliar na sua declaração. Se
            precisar é só me chamar, o custo para realizar a sua declaração é
            infinitamente menor que os problemas que a falta dela pode te
            ocasionar. O valor que cobro é à partir de R$ 100,00 por Declaração.
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Card
            sx={{
              maxWidth: 700,
              aspectRatio: { xs: "none", md: "16/9" },
            }}
          >
            <CardActionArea>
              <CardMedia
                component="img"
                height="240"
                image={imagePerfil}
                alt="green iguana"
              />
              <CardContent>
                <Typography
                  sx={{
                    fontWeight: 700,
                    fontSize: "24px",
                  }}
                >
                  Fernando Bertuzzo
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Bacharel em Direito, graduado pelo Instituto de Ensino
                  Superior de Bauru - IESB. Tecnólogo em Recursos Humanos pela
                  faculdade Anhanguera de Bauru. Experiência profissional com
                  mais de 10 anos na área de Departamento Pessoal e Recursos
                  Humanos. Mais de 500 declarações de imposto de renda pessoa
                  física - IRPF realizadas durante 8 anos.
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Box>
        <Box
          sx={{
            display: { xs: "", md: "flex" },
            flexDirection: { xs: "", md: "row" },
            justifyContent: { xs: "", md: "center" },
          }}
        >
          <BotaoWhats />
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default Declaracao;
